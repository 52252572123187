<template>
	<v-dialog persistent max-width="764px" ref="updateDialog" v-model="dialog">
		<v-form lazy-validation ref="form" v-model="valid">
			<v-card>
				<v-card-title class="pl-4 pr-4">
					<span class="text-h5 mb-0">Save Changes</span>
				</v-card-title>
				<v-card-text class="pb-0 my-1">
                    <span class="text-subtitle-1">Are you sure that you want to save all changes?</span>
                </v-card-text>
				<v-card-actions class="pl-4 pr-4">
					<v-spacer></v-spacer>
					<v-btn text @click="close">Close</v-btn>
					<v-btn text color="primary" :loading="loading" @click="save">Save Changes</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>


<script>

import { stringifyJSON } from '@/utils/encodes'

export default {
	name: 'PermissionUpdateDialog',
	data() {
		return {
			dialog: false,
			valid: true,
			loading: false,
            object: {
                category: '',
                items: [],
            }
		}
	},
	computed: {
		updateDialog() {
			return this.$refs.updateDialog
		},
		form() {
			return this.$refs.form
		}
	},
	methods: {
		open: function() {
			this.dialog = true
		},
		close: function() {
			this.dialog = false
			this.loading = false
			this.form.reset()
			this.form.resetValidation()
            this.object = { name: '', id: '', description: '', is_visible: '' }
            this.errors = {}
        },
        updateItems: function(item) {
            this.object.category = item.category
            this.object.items = stringifyJSON(item.items)
        },
		save: function() {
			if(this.form.validate()){
				this.loading = true

                this.$store.dispatch('epanel/role/updateRolePermissions', { id: this.$route.params.id, object: this.object }).then((response) => {
					this.$emit('updated', { object: response.data.object, message: response.message })
                    this.close()
                }).catch(() => {
                    this.loading = false
                })
			}
		}
	}
}

</script>