var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('v-sheet',{staticClass:"pa-0",attrs:{"color":"white","elevation":"0"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"10","lg":"10","xl":"10"}},[_c('p',{staticClass:"text-h5 mb-4"},[_vm._v("Role Permission "),_c('span',{domProps:{"textContent":_vm._s(_vm.getRoleText())}})])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","xs":"12","sm":"12","md":"2","lg":"2","xl":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.back}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-left")])],1)]}}])},[_c('span',[_vm._v("Back")])])],1),(!_vm.loading && !_vm.permissions.can_list)?_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","xs":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('p',{staticClass:"text-title mb-0"},[_vm._v("You do not have permission to view this role's permissions")])]):_vm._e()],1)],1)],1)],1),(_vm.permissions.can_list)?_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","xs":"12","sm":"12","md":"4","lg":"4","xl":"4"}},[_c('v-autocomplete',{staticClass:"mr-2",attrs:{"dense":"","hide-details":"","label":"Category","items":_vm.categories},model:{value:(_vm.filters.category),callback:function ($$v) {_vm.$set(_vm.filters, "category", $$v)},expression:"filters.category"}}),_c('v-btn',{staticClass:"mr-2 elevation-0",attrs:{"small":"","color":"primary","ripple":false},on:{"click":_vm.get}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-magnify")]),_vm._v(" View ")],1)],1),(_vm.permissions.can_edit)?_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","xs":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('v-btn',{staticClass:"elevation-0",attrs:{"small":"","color":"primary","ripple":false},on:{"click":_vm.openUpdateDialog}},[_vm._v("Save Changes")])],1):_vm._e()],1):_vm._e(),(_vm.permissions.can_list)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"calculate-widths":"","hide-default-header":"","hide-default-footer":"","loading-text":"Loading... Please wait...","no-data-text":"No data available yet","loading":_vm.loading,"items-per-page":50,"headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',[(!_vm.loading && _vm.items.length > 0)?_c('th',{staticClass:"text-center",attrs:{"width":"5%"}},[(_vm.permissions.can_edit)?_c('v-checkbox',{staticClass:"max-auto",on:{"change":_vm.selectItems},model:{value:(_vm.isSelectedAll),callback:function ($$v) {_vm.isSelectedAll=$$v},expression:"isSelectedAll"}}):_vm._e()],1):_vm._e(),_vm._l((props.headers),function(head,index){return _c('th',{key:index,staticClass:"text-center",domProps:{"textContent":_vm._s(head.text)}})})],2)])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[(!_vm.loading)?_c('td',{staticClass:"text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center"},[_c('v-checkbox',{attrs:{"disabled":!_vm.permissions.can_edit},on:{"change":function($event){return _vm.selectItem(item)}},model:{value:(item.is_granted),callback:function ($$v) {_vm.$set(item, "is_granted", $$v)},expression:"item.is_granted"}})],1):_vm._e(),_c('td',{staticClass:"text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center",domProps:{"textContent":_vm._s(item.name)}}),_c('td',{staticClass:"text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center",domProps:{"textContent":_vm._s(item.description)}})])]}}],null,false,2324291155)})],1)],1):_vm._e(),_c('update-dialog',{ref:"updateDialog",on:{"updated":_vm.updateItems}}),_c('message-notifier',{ref:"notifier"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }