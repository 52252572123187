<template>
	<div>
		<v-row>
			<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
				<v-sheet color="white" elevation="0" class="pa-0">
					<v-row>
                        <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10">
                            <p class="text-h5 mb-4">Role Permission <span v-text="getRoleText()"></span></p>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2" class="text-right">
                            <v-tooltip bottom><template v-slot:activator="{ on, attrs }"><v-btn icon v-bind="attrs" v-on="on" @click="back"><v-icon>mdi-arrow-left</v-icon></v-btn></template><span>Back</span></v-tooltip>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pt-0" v-if="!loading && !permissions.can_list">
                            <p class="text-title mb-0">You do not have permission to view this role's permissions</p>
                        </v-col>
					</v-row>
                </v-sheet>
			</v-col>
		</v-row>
		<v-row v-if="permissions.can_list">
			<v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="d-flex">
                <v-autocomplete dense hide-details label="Category" class="mr-2" :items="categories" v-model="filters.category"></v-autocomplete>
                <v-btn small color="primary" class="mr-2 elevation-0" :ripple="false" @click="get">
                    <v-icon small left>mdi-magnify</v-icon> View
                </v-btn>
			</v-col>
			<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pb-0" v-if="permissions.can_edit">
                <v-btn small color="primary" class="elevation-0" :ripple="false" @click="openUpdateDialog">Save Changes</v-btn>
			</v-col>
		</v-row>
		<v-row v-if="permissions.can_list">
			<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
				<v-data-table calculate-widths hide-default-header hide-default-footer class="elevation-0" loading-text="Loading... Please wait..." no-data-text="No data available yet" :loading="loading" :items-per-page="50" :headers="headers" :items="items">
					<template v-slot:header="{ props }">
                        <thead class="v-data-table-header">
                            <tr>
                                <th class="text-center" width="5%" v-if="!loading && items.length > 0">
                                    <v-checkbox class="max-auto" v-model="isSelectedAll" @change="selectItems" v-if="permissions.can_edit"></v-checkbox>
                                </th>
                                <th class="text-center" :key="index" v-for="(head, index) in props.headers" v-text="head.text"></th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:item='{ item }'>
						<tr>
                            <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-if="!loading">
                                <v-checkbox :disabled="!permissions.can_edit" v-model="item.is_granted"  @change="selectItem(item)"></v-checkbox>
                            </td>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.name"></td>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.description"></td>
						</tr>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
		<update-dialog ref="updateDialog" @updated="updateItems"></update-dialog>
		<message-notifier ref="notifier"></message-notifier>
	</div>
</template>


<script>

import MessageNotifier from '../../../../shared/MessageNotifier.vue'
import PermissionUpdateDialog from './PermissionUpdateDialog.vue'

export default {
	name: 'PermissionList',
	components: {
        messageNotifier: MessageNotifier,
        updateDialog: PermissionUpdateDialog,
	},
	data () {
		return {
			loading: false,
			isSelectedAll: false,
			selectedItems: [],
            filters: {
                category: ''
            },
			object: {
                name: '',
			},
            permissions: {
                can_list: false,
                can_edit: false,
            },
			categories: [],
			items: [],
			headers: [],
		}
	},
	computed: {
        updateDialog() {
            return this.$refs.updateDialog
        },
        notifier() {
            return this.$refs.notifier
        }
	},
	mounted: function() {
		this.get()
	},
	methods: {
        back: function(){
            this.$router.replace('/epanel/account/role')
        },
		get: function() {
			this.loading = true
			this.isSelectedAll = false
			this.selectedItems = []

			this.$store.dispatch('epanel/role/getRolePermissions', {id: this.$route.params.id, object: this.filters}).then((response) => {
                if(this.categories.length === 0){
                    this.object.name = response.data.object.name
                    this.headers = response.data.headers
                    this.categories = response.data.categories
                }
                this.permissions = response.data.permissions
                this.filters.category = response.data.object.category
                this.items = response.data.items
                this.updateSelectedItems()
                this.loading = false
			}).catch(() => {
				this.loading = false
			})
		},
        openUpdateDialog: function(){
            this.updateDialog.updateItems({ category: this.filters.category, items: this.selectedItems })
            this.updateDialog.open()
        },
		openNotifier: function(message) {
            this.notifier.updateText(message)
            this.notifier.open()
        },
        selectItem: function(item){
            if(this.permissions.can_edit){
                const itemIndex = this.selectedItems.indexOf(item.id)
                if(itemIndex > -1){
                    this.selectedItems.splice(itemIndex, 1)
                }else{
                    this.selectedItems.push(item.id)
                }
                this.isSelectedAll = this.selectedItems.length === this.items.length
            }
        },
        selectItems: function(isSelected){
            if(this.permissions.can_edit){
                this.selectedItems = []
                if(!isSelected){
                    this.items.forEach((item) => item.is_granted = false )
                }else{
                    this.items.forEach((item) => {
                        item.is_granted = true
                        this.selectedItems.push(item.id)
                    })
                }
            }
        },
        updateItems: function(item){
            this.get()
            this.openNotifier(item.message)
        },
        updateSelectedItems: function(){
            this.items.forEach((item) => {
                if(item.is_granted){
                    this.selectedItems.push(item.id)
                }
            })
            this.isSelectedAll = this.selectedItems.length === this.items.length
        },
        getRoleText: function() {
            return this.object.name?' - ' + this.object.name:''
        }
	}
}

</script>